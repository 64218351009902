import {
  CourseCreateRequest,
  CourseUpdateRequest,
  CourseGetAllRequest,
  AddImageToCourse,
  RemoveImageOnCourse,
  GetCourseByIdRequest
} from '../utils/DataSchemas'

import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

function getPaginationQuery(getAllQuery: CourseGetAllRequest) {
  let query: string = '';

  if (typeof getAllQuery.offset !== 'undefined') {
    query += `${query ? '&' : '?'}offset=${getAllQuery.offset}`
  }

  if (typeof getAllQuery.limit !== 'undefined') {
    query += `${query ? '&' : '?'}limit=${getAllQuery.limit}`
  }

  if (typeof getAllQuery.tags !== 'undefined') {
    const tagsFormat: string = getAllQuery.tags.map((tag) => `&tags=${tag}`).join('')
    query += tagsFormat
  }

  if (typeof getAllQuery.published_only !== 'undefined') {
    query += `&published_only=${getAllQuery.published_only}`
  }

  if (typeof getAllQuery.search_text !== 'undefined') {
    query += `&search_text=${getAllQuery.search_text}`
  }

  if (typeof getAllQuery.expert_ids !== "undefined") {
    const expertsIds: string = getAllQuery.expert_ids
      .map((p: any, index: number) =>
        index === 0 && !query ? `?expert_ids=${p}` : `&expert_ids=${p}`
      )
      .join('')
    query += expertsIds
  }
  return query
}

export default class CourseService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getAll(getAllQuery: CourseGetAllRequest) {
    const query = getPaginationQuery(getAllQuery)

    return request('GET', `/courses/${query}`, this.options)
  }

  // GET

  async getCoursesById({
    course_id,
    published_only
  }: GetCourseByIdRequest) {
    let query = ''

    if (typeof published_only !== 'undefined') {
      query += `?published_only=${published_only}`
    }

    return request('GET', `/courses/${course_id}${query}`, this.options)
  }

  async getAdminCoursesById({
    course_id,
    published_only
  }: GetCourseByIdRequest) {
    let query = ''

    if (typeof published_only !== 'undefined') {
      query += `?published_only=${published_only}`
    }

    return request('GET', `/admin/courses/${course_id}${query}`, this.options)
  }

  async reSyncCourseDuration() {
    return request('GET', `/admin/courses/re-sync-course-duration`, this.options)
  }


  // POST

  async createCourse(data: CourseCreateRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/admin/courses/`, requestOptions)
  }

  async addImageToCourse({ course_id, field, image }: AddImageToCourse) {
    const formData = new FormData()
    formData.append('image', image)

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }
    return request(
      'POST',
      `/admin/courses/${course_id}/images?field=${field}`,
      requestOptions
    )
  }

  // PUT

  async updateCourse({ course_id, ...data }: CourseUpdateRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `/admin/courses/${course_id}`, requestOptions)
  }


  // DELETE 

  async deleteCourse(course_id: number) {
    return request('DELETE', `/admin/courses/${course_id}`, this.options)
  }

  async removeImageOnCourse({ course_id, field }: RemoveImageOnCourse) {
    return request(
      'DELETE',
      `/admin/courses/${course_id}/images?field=${field}`,
      this.options
    )
  }
}
