import {
  CoursesActionTypeEnum,
  VideoActionTypeEnum,
  ModuleConfigActionTypeEnum,
  CoursesConfigActionTypeEnum
} from "../../helpers/enums/actionTypeEnums"
import { arrayReduce, updatedArray } from "../../../../../utility/helpers/functions";


const initialState = {
  Categories: [],
  Courses: [],
  Videos: [],
  total: 0,
  CoursesTotal: null,
  VideosTotal: 0,
  params: {},
  allData: [],
  ConfigData: {},
  ConfigDataVideos: [],
  ConfigDataTags: [],
  ConfigDataCourseIds: [],
  ConfigDataCourseObjects: null,
  ConfigDataCourses: [],
  Sections: [],
  SectionToUpdate: {},
  selectDefaultCourses: [],
  DefaultCourses: [],
  BlockModeCoursesLoading: false,
  DataLoading: true,
  DefaultExperts: [],
  ConfigDataLoading: true
};

const getObjectsArrayReturnIdsArray = (array) => {
  let tab = [];
  for (let i = 0; i < array.length; i++) {
    tab.push(array[i].id);
  }
  return tab;
};

const AcademyReducer = (state = initialState, action) => {
  switch (action.type) {
    case CoursesActionTypeEnum.AddDefaultCourses:
      return {
        ...state,
        DefaultCourses: action.default,
      };

    case CoursesActionTypeEnum.List:
      return {
        ...state,
        Courses: action.courses,
        CoursesTotal: action.count,
        DataLoading: action.loading
      };

      case CoursesActionTypeEnum.Loading:
        return {
          ...state,
          DataLoading: action.loading
        };

    case CoursesActionTypeEnum.Filter:
      return {
        ...state,
        Courses: action.courses,
      };

    case CoursesActionTypeEnum.ListInSelect:
      return {
        ...state,
        selectDefaultCourses: action.courses,
      };
    case CoursesActionTypeEnum.Add:
      let newCoursesState = !state.Courses ? [] : state.Courses;
      newCoursesState.unshift(action.courses);
      return {
        ...state,
        Courses: newCoursesState,
        CoursesTotal: newCoursesState.count
      };
    case CoursesActionTypeEnum.Update:
      let coursesState = !state.Courses ? [] : state.Courses;
      let newArrayCourses = updatedArray(coursesState, action.courses);
      return {
        ...state,
        Courses: newArrayCourses,
      };
    case CoursesActionTypeEnum.Delete:
      let COURSES = !state.Courses ? [] : state.Courses;
      let updCoursesArray = arrayReduce(COURSES, action.courses);
      return {
        ...state,
        Courses: updCoursesArray,
        CoursesTotal: updCoursesArray.length
      };

    case VideoActionTypeEnum.List:
      return {
        ...state,
        Videos: action.videos,
        VideosTotal: action.videos.length,
      };

    case VideoActionTypeEnum.Add:
      let newVideoState = !state.Videos ? [] : state.Videos;
      newVideoState.unshift(action.video);
      return {
        ...state,
        Videos: newVideoState,
        VideosTotal: newVideoState.length,
      };
    case VideoActionTypeEnum.SetEmpty:
      return {
        ...state,
        Videos: [],
      };
    case VideoActionTypeEnum.BlockModeLoading:
      return {
        ...state,
        BlockModeCoursesLoading: action.data,
      };
    case VideoActionTypeEnum.Update:
      let videoState = !state.Videos ? [] : state.Videos;
      let newArrayVideo = updatedArray(videoState, action.video);
      return {
        ...state,
        Videos: newArrayVideo,
      };
    case VideoActionTypeEnum.Delete:
      let VIDEO = !state.Videos ? [] : state.Videos;
      let updVideoArray = arrayReduce(VIDEO, action.video);
      return {
        ...state,
        Videos: updVideoArray,
        VideosTotal: updVideoArray.length,
      };

    case ModuleConfigActionTypeEnum.GetData:
      return {
        ...state,
        ConfigData: action.configData,
        ConfigDataTags: action.configDataTags,
        ConfigDataCourseIds: action.configDataCourseIds,
        ConfigDataCourseObjects: action.configDataCourseObjects,
        ConfigDataLoading: false
      };

    case ModuleConfigActionTypeEnum.Update:

      action.client.updateModuleConfig({
        default_tag_ids: [],
        highlight_course_ids: state.ConfigDataCourseIds
      }).then((response) => {
        return {
          ...state,
        ConfigData: response,
        configDataTags: response.default_tags,
        configDataCourseIds: response.highlight_course_ids,
        configDataCourseObjects: response.highlight_course_objects,
        }
       }).catch((error) => { 
        console.error(error);
        return state
        });

    case ModuleConfigActionTypeEnum.AddTag:
      let configTagState = !state.ConfigDataTags ? [] : state.ConfigDataTags;
      configTagState.unshift(action.configDataTag);
      return {
        ...state,
        ConfigDataTags: configTagState,
      };
    case ModuleConfigActionTypeEnum.DeleteTag:
      let configTagArray = !state.ConfigDataTags ? [] : state.ConfigDataTags;
      let configTagUpdated = arrayReduce(configTagArray, action.configDataTag);
      return {
        ...state,
        ConfigDataTags: configTagUpdated,
      };

    case ModuleConfigActionTypeEnum.AddCourseIds:
      let configDataCourseIds = !state.ConfigDataCourseIds ? [] : state.ConfigDataCourseIds;
      configDataCourseIds.unshift(action.configDataCourseIds);
      let configDataCourseObjects = !state.ConfigDataCourseObjects ? [] : state.ConfigDataCourseObjects;
      configDataCourseObjects.unshift(action.configDataCourseObjects);
      return {
        ...state,
        ConfigDataCourseIds: configDataCourseIds,
        ConfigDataCourseObjects: configDataCourseObjects
      };
    case ModuleConfigActionTypeEnum.DeleteCourseId:
      let configCoursesArray = !state.ConfigDataCourseObjects ? [] : state.ConfigDataCourseObjects;
      let ConfigDataCourseCoursesUpdated = arrayReduce(configCoursesArray, action.configDataCourseObjects);
      let configCoursesIdsArray = !state.ConfigDataCourseIds ? [] : state.ConfigDataCourseIds;
      let ConfigDataCourseIdsUpdated = arrayReduce(configCoursesIdsArray, action.configDataCourseIds);
      return {
        ...state,
        ConfigDataCourseObjects: ConfigDataCourseCoursesUpdated,
        ConfigDataCourseIds: ConfigDataCourseIdsUpdated
      };

    case CoursesConfigActionTypeEnum.ClearSectionToUpdate: //For mikel
      return {
        ...state,
        SectionToUpdate: {},
      };

    case CoursesConfigActionTypeEnum.GetData:
      return {
        ...state,
        Sections: action.sections,
      };

    case CoursesConfigActionTypeEnum.LoadSections: //For mikel
      return {
        ...state,
        SectionToUpdate: action.sectionToUpdate,
      };

    case VideoActionTypeEnum.DefaultExperts:
      return {
        ...state,
        DefaultExperts: action.expert,
      };

    default:
      return state;
  }
};

export default AcademyReducer;
