export enum CoursesActionTypeEnum {
    List = "LIST_COURSES_ACADEMY",
    Filter = "FILTER_COURSES_ACADEMY",
    ListInSelect = "SELECT_LIST_COURSES_ACADEMY",
    AddDefaultCourses = "ADD_DEFAULT_COURSES_ACADEMY",
    Add = "ADD_COURSES_ACADEMY",
    Update = "UPDATE_COURSES_ACADEMY",
    Delete = "DELETE_COURSES_ACADEMY",
    Loading = "DATA_LOADING"
}

export enum VideoActionTypeEnum {
    List = "LIST_VIDEOLIST_VIDEOS_ACADEMY",
    BlockModeLoading = "SET_BLOCK_MODE_VIDEOS_LOADING_ACADEMY",
    SetEmpty = "SET_VIDEOS_EMPTY_ACADEMY",
    Get = "GET_CONFIG_MAGAZINE",
    Update = "UPDATE_VIDEO_ACADEMY",
    Add = "ADD_VIDEO_ACADEMY",
    Delete = "DELETE_VIDEO_ACADEMY",
    DefaultExperts = "DEFAULT_EXPERTS",
}

export enum ModuleConfigActionTypeEnum {
    GetData = "GET_CONFIG_ACADEMY",
    BlockModeLoading = "SET_BLOCK_MODE_VIDEOS_LOADING_ACADEMY",
    Update = "UPDATE_CONFIG_ACADEMY",
    // AddVideo = "ADD_CONFIG_VIDEO_ACADEMY",
    // DeleteVideo = "DELETE_CONFIG_VIDEO_ACADEMY",
    // AddCourses = "ADD_CONFIG_COURSES_ACADEMY",
    // DeleteCourses = "DELETE_CONFIG_COURSES_ACADEMY",
    AddCourseIds = "ADD_CONFIG_COURSE_ID_ACADEMY",
    DeleteCourseId = "DELETE_CONFIG_COURSE_ID_ACADEMY",
    AddTag = "ADD_CONFIG_TAG_ACADEMY",
    DeleteTag = "DELETE_CONFIG_TAG_ACADEMY",
}

export enum CoursesConfigActionTypeEnum {
    GetData = "GET_COURSES_CONFIG_ACADEMY",
    LoadSections = "LOAD_SECTION_TO_UPDATE_ACADEMY",
    ClearSectionToUpdate = "CLEAR_SECTION_TO_UPDATE_ACADEMY",
}
